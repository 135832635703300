<template>
  <v-snackbar :timeout="timeout" v-bind="$attrs" v-on="$listeners">
    <slot>Hi from snackbar!</slot>
    <template v-slot:action="{ attrs }">
      <slot name="commands">
        <v-btn text v-bind="attrs" v-if="visibleCloseButton" @click="close"
          >Close</v-btn
        >
      </slot>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "BaseSnackbar",
  props: {
    timeout: {
      type: Number,
      default: 2000
    },
    visibleCloseButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
